import { PageProps } from 'gatsby';
import { ExtractData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import extract from '~/params/extract.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const EspaceExtractUpdate: FC<PageProps & EspaceProps & UserProps> = props => {
  const {
    espace,
    user,
    params: { espaceId, extractId },
  } = props;

  const model = new ExtractData({ espaceId: espace.id, params: extract });

  return (
    <TemplateEspace
      collectionName="extracts"
      docId={extractId}
      espace={espace}
      user={user}
    >
      <CMSView>
        <Form
          docId={extractId}
          itemPathnamePrefix={`/espaces/${espaceId}/notes/extracts/`}
          model={model}
          name="extract"
          type="update"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(EspaceExtractUpdate);
